import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-hallow-to-lullaby"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Hallow to Lullaby</span>
    </h2>
    <h6><a id="hallow">Hallow</a></h6>
    <p className="initial"><i>Evocation [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 5</span> <span className="stat-block"><b>Components</b>: V, S, M, DF</span> <span className="stat-block"><b>Casting Time</b>: 24 hours</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Area</b>:
40-ft. radius emanating
from the touched point</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: See text</span>
    <p>Hallow makes a particular site, building, or structure a holy
site. This has four major effects.</p>
    <p>First, the site or structure is guarded by a magic circle
against evil effect.</p>
    <p>Second, all Charisma checks made to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn
undead</a> gain a +4 sacred bonus, and Charisma checks to command
undead take a &ndash;4 penalty. Spell resistance does not apply to this
effect. (This provision does not apply to the druid version of the
spell.)</p>
    <p>Third, any <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a> body interred in a hallowed
site cannot be turned into an undead creature.</p>
    <p>Finally, you may choose to fix a single spell effect to the
hallowed site. The spell effect lasts for one year and functions
throughout the entire site, regardless of the normal duration and area
or effect. You may designate whether the effect applies to all
creatures, creatures who share your faith or alignment, or creatures
who adhere to another faith or alignment. At the end of the year, the
chosen effect lapses, but it can be renewed or replaced simply by
casting hallow again.</p>
    <p>Spell effects that may be tied to a hallowed site include aid,
bane, bless, cause fear, darkness, daylight, death ward, deeper
darkness, detect evil, detect magic, dimensional anchor, discern lies,
dispel magic, endure elements, freedom of movement, invisibility purge,
protection from energy, remove fear, resist energy, silence, tongues,
and zone of truth. Saving throws and spell resistance might apply to
these spells&rsquo; effects. (See the individual spell descriptions for
details.)</p>
    <p>An area can receive only one hallow spell (and its associated
spell effect) at a time. Hallow counters but does not dispel unhallow.</p>
    <p><i>Material Component</i>: Herbs, oils, and incense worth at
least 1,000 gp, plus 1,000 gp per level of the spell to be included in
the hallowed area.</p>
    <h6><a id="hallucinatory-terrain">Hallucinatory Terrain</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: One 30-ft.
cube/level (S)</span> <span className="stat-block"><b>Duration</b>: 2
hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will disbelief (if
interacted with)</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>You make natural terrain look, sound, and smell like some
other sort of natural terrain. Structures, equipment, and creatures
within the area are not hidden or changed in appearance.</p>
    <p><i>Material Component</i>: A stone, a twig, and a bit of green
plant.</p>
    <h6><a id="halt-undead">Halt Undead</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: Up to three
undead
creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates (see
text)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell renders as many as three undead creatures immobile.
A nonintelligent undead creature gets no saving throw; an intelligent
undead creature does. If the spell is successful, it renders the undead
creature immobile for the duration of the spell (similar to the effect
of hold person on a living creature). The effect is broken if the
halted creatures are attacked or take damage.</p>
    <p><i>Material Component</i>: A pinch of sulfur and powdered
garlic.</p>
    <h6><a id="harm">Harm</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Destruction 6</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will half; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Harm charges a subject with negative energy that deals 10
points of damage per caster level (to a maximum of 150 points at 15th
level). If the creature successfully saves, harm deals half this
amount, but it cannot reduce the target&rsquo;s hit points to less than
1.</p>
    <p>If used on an undead creature, harm acts like heal.</p>
    <h6><a id="haste">Haste</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The transmuted creatures move and act more quickly than
normal. This extra speed has several effects.</p>
    <p>When making a full attack action, a hasted creature may make
one extra attack with any weapon he is holding. The attack is made
using the creature&rsquo;s full base attack bonus, plus any modifiers
appropriate to the situation. (This effect is not cumulative with
similar effects, such as that provided by a weapon of speed, nor does
it actually grant an extra action, so you can&rsquo;t use it to cast a
second
spell or otherwise take an extra action in the round.)</p>
    <p>A hasted creature gains a +1 bonus on attack rolls and a +1
dodge bonus to AC and Reflex saves. Any condition that makes you lose
your Dexterity bonus to Armor Class (if any) also makes you lose dodge
bonuses.</p>
    <p>All of the hasted creature&rsquo;s modes of movement
(including land
movement, burrow, climb, fly, and swim) increase by 30 feet, to a
maximum of twice the subject&rsquo;s normal speed using that form of
movement. This increase counts as an enhancement bonus, and it affects
the creature&rsquo;s jumping distance as normal for increased speed.</p>
    <p>Multiple haste effects don&rsquo;t stack. Haste dispels and
counters
slow.</p>
    <p><i>Material Component</i>: A shaving of licorice root.</p>
    <h6><a id="heal">Heal</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 7, Healing 6</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>Heal enables you to channel positive energy into a creature to
wipe away injury and afflictions. It immediately ends any and all of
the following adverse conditions affecting the Target: <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a>, <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>, <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a>, <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazzled</a>, <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a>, diseased, <a href="abilitiesAndConditions.html#exhausted" style={{
        "color": "rgb(87, 158, 182)"
      }}>exhausted</a>, <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a>, feebleminded,
insanity, <a href="abilitiesAndConditions.html#nauseated" style={{
        "color": "rgb(87, 158, 182)"
      }}>nauseated</a>, <a href="abilitiesAndConditions.html#sickened" style={{
        "color": "rgb(87, 158, 182)"
      }}>sickened</a>, <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>, and poisoned. It also
cures 10 hit points of damage per level of the caster, to a maximum of
150 points at 15th level.</p>
    <p>Heal does not remove <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>negative levels</a>, restore
permanently drained levels, or restore permanently drained ability
score points.</p>
    <p>If used against an undead creature, heal instead acts like
harm.</p>
    <h6><a id="mass-heal">Heal, Mass</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Healing 9</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One or more
creatures,
no two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#heal">heal</a>, except as noted above. The
maximum number of hit points restored to each creature is 250.</p>
    <h6><a id="heal-mount">Heal Mount</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Pal 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Your mount touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#heal">heal</a>, but it affects only the
paladin&rsquo;s special mount (typically a warhorse).</p>
    <h6><a id="heat-metal">Heat Metal</a></h6>
    <p className="initial"><i>Transmutation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Sun 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: Metal equipment
of one
creature per two levels, no two of which can be more than 30 ft. apart;
or 25 lb. of metal/level, all of which must be within a 30-ft. circle</span>
    <span className="stat-block"><b>Duration</b>: 7 rounds</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>Heat metal makes metal extremely warm. Unattended, nonmagical
metal gets no saving throw. Magical metal is allowed a saving throw
against the spell. An item in a creature&rsquo;s possession uses the
creature&rsquo;s saving throw bonus unless its own is higher.</p>
    <p>A creature takes fire damage if its equipment is heated. It
takes full damage if its armor is affected or if it is holding,
touching, wearing, or carrying metal weighing one-fifth of its weight.
The creature takes minimum damage (1 point or 2 points; see the table)
if it&rsquo;s not wearing metal armor and the metal that it&rsquo;s
carrying weighs
less than one-fifth of its weight.</p>
    <p>On the first round of the spell, the metal becomes warm and
uncomfortable to touch but deals no damage. The same effect also occurs
on the last round of the spell&rsquo;s duration. During the second (and
also
the next-to-last) round, intense heat causes pain and damage. In the
third, fourth, and fifth rounds, the metal is searing hot, causing more
damage, as shown on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "75px"
          }}>Round</th>
          <th style={{
            "width": "115px"
          }}>Metal
Temperature</th>
          <th style={{
            "width": "75px"
          }}>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>1</td>
          <td>Warm</td>
          <td>None</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Hot</td>
          <td>1d4 points</td>
        </tr>
        <tr className="odd-row">
          <td>3&ndash;5</td>
          <td>Searing</td>
          <td>2d4 points</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Hot</td>
          <td>1d4 points</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>Warm</td>
          <td>None</td>
        </tr>
      </tbody>
    </table>
    <p>Any cold intense enough to damage the creature negates fire
damage from the spell (and vice versa) on a point-for-point basis. If
cast underwater, heat metal deals half damage and boils the surrounding
water.</p>
    <p>Heat metal counters and dispels chill metal.</p>
    <h6><a id="helping-hand">Helping Hand</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 5 miles</span> <span className="stat-block"><b>Effect</b>: Ghostly hand</span> <span className="stat-block"><b>Duration</b>: 1 hour/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You create the ghostly image of a hand, which you can send to
find a creature within 5 miles. The hand then beckons to that creature
and leads it to you if the creature is willing to follow.</p>
    <p>When the spell is cast, the hand appears in front of you. You
then specify a person (or any creature) by physical description, which
can include race, gender, and appearance but not ambiguous factors such
as level, alignment, or class. When the description is complete, the
hand streaks off in search of a subject that fits the description. The
amount of time it takes to find the subject depends on how far away she
is.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Distance</th>
          <th style={{
            "width": "125px"
          }}>Time to Locate</th>
        </tr>
        <tr className="odd-row">
          <td>100 ft. or less</td>
          <td>1 round</td>
        </tr>
        <tr>
          <td>1,000 ft.</td>
          <td>1 minute</td>
        </tr>
        <tr className="odd-row">
          <td>1 mile</td>
          <td>10 minutes</td>
        </tr>
        <tr>
          <td>2 miles</td>
          <td>1 hour</td>
        </tr>
        <tr className="odd-row">
          <td>3 miles</td>
          <td>2 hours</td>
        </tr>
        <tr>
          <td>4 miles</td>
          <td>3 hours</td>
        </tr>
        <tr className="odd-row">
          <td>5 miles</td>
          <td>4 hours</td>
        </tr>
      </tbody>
    </table>
    <p>Once the hand locates the subject, it beckons the creature to
follow it. If the subject does so, the hand points in your direction,
indicating the most direct feasible route. The hand hovers 10 feet in
front of the subject, moving before it at a speed of as much as 240
feet per round. Once the hand leads the subject back to you, it
disappears.</p>
    <p>The subject is not compelled to follow the hand or act in any
particular way toward you. If the subject chooses not to follow, the
hand continues to beckon for the duration of the spell, then
disappears. If the spell expires while the subject is en route to you,
the hand disappears; the subject must then rely on her own devices to
locate you.</p>
    <p>If more than one subject in a 5-mile radius meets the
description, the hand locates the closest creature. If that creature
refuses to follow the hand, the hand does not seek out a second subject.</p>
    <p>If, at the end of 4 hours of searching, the hand has found no
subject that matches the description within 5 miles, it returns to you,
displays an outstretched palm (indicating that no such creature was
found), and disappears.</p>
    <p>The ghostly hand has no physical form. It is <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> to anyone except you
and a potential subject. It cannot engage in combat or execute any
other task aside from locating a subject and leading it back to you.
The hand can&rsquo;t pass through solid objects but can ooze through
small
cracks and slits. The hand cannot travel more than 5 miles from the
spot it appeared when you cast the spell.</p>
    <h6><a id="heroes-feast">Heroes&rsquo; Feast</a></h6>
    <p className="initial"><i>Conjuration [Creation]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Clr 6</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Feast for one
creature/level</span> <span className="stat-block"><b>Duration</b>: 1 hour
plus 12 hours;
see text</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You bring forth a great feast, including a magnificent table,
chairs, service, and food and drink. The feast takes 1 hour to consume,
and the beneficial effects do not set in until this hour is over. Every
creature partaking of the feast is cured of all diseases, sickness, and
nausea; becomes immune to poison for 12 hours; and gains 1d8 temporary
hit points +1 point per two caster levels (maximum +10) after imbibing
the nectar-like beverage that is part of the feast. The ambrosial food
that is consumed grants each creature that partakes a +1 morale bonus
on attack rolls and Will saves and immunity to fear effects for 12
hours.</p>
    <p>If the feast is interrupted for any reason, the spell is
ruined and all effects of the spell are negated.</p>
    <h6><a id="heroism">Heroism</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 10
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>This spell imbues a single creature with great bravery and
morale in battle. The target gains a +2 morale bonus on attack rolls,
saves, and skill checks.</p>
    <h6><a id="greater-heroism">Heroism, Greater</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 6</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#heroism">heroism</a>, except the creature gains a
+4 morale bonus on attack rolls, saves, and skill checks, immunity to
fear effects, and temporary hit points equal to your caster level
(maximum 20).</p>
    <h6><a id="hide-from-animals">Hide from Animals</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1</span> <span className="stat-block"><b>Components</b>: S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One creature
touched/level</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Animals cannot see, hear, or smell the warded creatures. Even
extraordinary or supernatural sensory capabilities, such as blindsense,
blindsight, scent, and tremorsense, cannot detect or locate warded
creatures. Animals simply act as though the warded creatures are not
there. If a warded character touches an animal or attacks any creature,
even with a spell, the spell ends for all recipients.</p>
    <h6><a id="hide-from-undead">Hide from Undead</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One touched
creature/level</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless); see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>Undead cannot see, hear, or smell the warded creatures. Even
extraordinary or supernatural sensory capabilities, such as blindsense,
blindsight, scent, and tremorsense, cannot detect or locate warded
creatures. Nonintelligent undead creatures are automatically affected
and act as though the warded creatures are not there. An intelligent
undead creature gets a single Will saving throw. If it fails, the
subject can&rsquo;t see any of the warded creatures. However, if it has
reason to believe unseen opponents are present, it can attempt to find
or strike them. If a warded creature attempts to turn or command
undead, touches an undead creature, or attacks any creature (even with
a spell), the spell ends for all recipients.</p>
    <h6><a id="hideous-laughter">Hideous Laughter</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature;
see text</span> <span className="stat-block"><b>Duration</b>: 1 round/level</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell afflicts the subject with uncontrollable laughter.
It collapses into gales of manic laughter, falling <a href="abilitiesAndConditions.html#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a>. The subject can take no
actions while laughing, but is not considered <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>. After the spell ends,
it can act normally.</p>
    <p>A creature with an Intelligence score of 2 or lower is not
affected. A creature whose type is different from the caster&rsquo;s
receives
a +4 bonus on its saving throw, because humor doesn&rsquo;t
&ldquo;translate&rdquo; well.</p>
    <p><i>Material Component</i>: Tiny tarts that are thrown at the
target and a feather that is waved in the air.</p>
    <h6><a id="hold-animal">Hold Animal</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Animal 2, Drd 2, Rgr 2</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Target</b>: One animal</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#hold-person">hold person</a>, except that it affects
an animal instead of a humanoid.</p>
    <h6><a id="hold-monster">Hold Monster</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Law 6, Sor/Wiz 5</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Target</b>: One living creature</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#hold-person">hold person</a>, except that it affects
any living creature that fails its Will save.</p>
    <p><i>Arcane Material Component</i>: One hard metal bar or rod,
which can be as small as a three-penny nail.</p>
    <h6><a id="mass-hold-monster">Hold Monster, Mass</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Targets</b>: One or more creatures,
no two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#hold-person">hold person</a>, except that it affects
multiple creatures and holds any living creature that fails its Will
save.</p>
    <h6><a id="hold-person">Hold Person</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Sor/Wiz 3</span>
    <span className="stat-block"><b>Components</b>: V, S, F/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One humanoid
creature</span> <span className="stat-block"><b>Duration</b>: 1
round/level (D); see
text</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject becomes <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a> and freezes in place.
It is aware and breathes normally but cannot take any actions, even
speech. Each round on its turn, the subject may attempt a new saving
throw to end the effect. (This is a full-round action that does not
provoke attacks of opportunity.)</p>
    <p>A winged creature who is paralyzed cannot flap its wings and
falls. A swimmer can&rsquo;t swim and may drown.</p>
    <p><i>Arcane Focus</i>: A small, straight piece of iron.</p>
    <h6><a id="mass-hold-person">Hold Person, Mass</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Targets</b>: One or more humanoid
creatures, no two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#hold-person">hold person</a>, except as noted above.</p>
    <h6><a id="hold-portal">Hold Portal</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Component</b>: V</span> <span className="stat-block"><b>Casting
Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>:
Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One portal,
up to 20 sq.
ft./level</span> <span className="stat-block"><b>Duration</b>: 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell magically holds shut a door, gate, window, or
shutter of wood, metal, or stone. The magic affects the portal just as
if it were securely closed and normally locked. A knock spell or a
successful dispel magic spell can negate a hold portal spell.</p>
    <p>For a portal affected by this spell, add 5 to the normal DC
for forcing open the portal.</p>
    <h6><a id="holy-aura">Holy Aura</a></h6>
    <p className="initial"><i>Abjuration [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Good 8</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 20 ft.</span> <span className="stat-block"><b>Targets</b>: One creature/level in a
20-ft.-radius burst centered on you</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(harmless)</span>
    <p>A brilliant divine radiance surrounds the subjects, protecting
them from attacks, granting them resistance to spells cast by evil
creatures, and causing evil creatures to become <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> when they strike the
subjects. This abjuration has four effects.</p>
    <p>First, each warded creature gains a +4 deflection bonus to AC
and a +4 resistance bonus on saves. Unlike protection from evil, this
benefit applies against all attacks, not just against attacks by evil
creatures.</p>
    <p>Second, each warded creature gains spell resistance 25 against
evil spells and spells cast by evil creatures.</p>
    <p>Third, the abjuration blocks possession and mental influence,
just as protection from evil does.</p>
    <p>Finally, if an evil creature succeeds on a melee attack
against a warded creature, the offending attacker is blinded (Fortitude
save negates, as blindness/deafness, but against holy aura&rsquo;s save
DC).</p>
    <p><i>Focus</i>: A tiny reliquary containing some sacred relic.
The reliquary costs at least 500 gp.</p>
    <h6><a id="holy-smite">Holy Smite</a></h6>
    <p className="initial"><i>Evocation [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Good 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous
(1
round); see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You draw down holy power to smite your enemies. Only evil and
neutral creatures are harmed by the spell; good creatures are
unaffected.</p>
    <p>The spell deals 1d8 points of damage per two caster levels
(maximum 5d8) to each evil creature in the area (or 1d6 points of
damage per caster level, maximum 10d6, to an evil outsider) and causes
it to become <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> for 1 round. A
successful Will saving throw reduces damage to half and negates the
blinded effect.</p>
    <p>The spell deals only half damage to creatures who are neither
good nor evil, and they are not blinded. Such a creature can reduce
that damage by half (down to one-quarter of the roll) with a successful
Will save.</p>
    <h6><a id="holy-sword">Holy Sword</a></h6>
    <p className="initial"><i>Evocation [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Pal 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Melee weapon touched</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell allows you to channel holy power into your sword,
or any other melee weapon you choose. The weapon acts as a +5 holy
weapon (+5 enhancement bonus on attack and damage rolls, extra 2d6
damage against evil opponents). It also emits a magic circle against
evil effect (as the spell). If the magic circle ends, the sword creates
a new one on your turn as a free action. The spell is automatically
canceled 1 round after the weapon leaves your hand. You cannot have
more than one holy sword at a time.</p>
    <p>If this spell is cast on a magic weapon, the powers of the
spell supersede any that the weapon normally has, rendering the normal
enhancement bonus and powers of the weapon inoperative for the duration
of the spell. This spell is not cumulative with bless weapon or any
other spell that might modify the weapon in any way.</p>
    <p>This spell does not work on artifacts.</p>
    <p><i>Note</i>: A masterwork weapon&rsquo;s bonus to attack does
not
stack with an enhancement bonus to attack.</p>
    <h6><a id="holy-word">Holy Word</a></h6>
    <p className="initial"><i>Evocation [Good, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Good 7</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft.</span> <span className="stat-block"><b>Area</b>: Nongood creatures in a
40-ft.-radius spread centered on you</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Any nongood creature within the area that hears the holy word
suffers the following ill effects.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>HD</th>
          <th>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>Equal to caster level</td>
          <td><a href="abilitiesAndConditions.html#deafened" style={{
              "color": "rgb(87, 158, 182)"
            }}>Deafened</a></td>
        </tr>
        <tr>
          <td>Up to caster level -1</td>
          <td><a href="abilitiesAndConditions.html#blinded" style={{
              "color": "rgb(87, 158, 182)"
            }}>Blinded</a>, deafened</td>
        </tr>
        <tr className="odd-row">
          <td>Up to caster level -5</td>
          <td><a href="abilitiesAndConditions.html#paralyzed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Paralyzed</a>, blinded, deafened</td>
        </tr>
        <tr>
          <td className="last-row">Up to caster level -10</td>
          <td className="last-row">Killed, paralyzed, blinded, deafened</td>
        </tr>
      </tbody>
    </table>
    <p>The effects are cumulative and concurrent. No saving throw is
allowed against these effects.</p>
    <p><i>Deafened</i>: The creature is deafened for 1d4 rounds.</p>
    <p><i>Blinded</i>: The creature is blinded for 2d4 rounds.</p>
    <p><i>Paralyzed</i>: The creature is paralyzed and <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> for 1d10 minutes.</p>
    <p><i>Killed</i>: Living creatures die. Undead creatures are
destroyed.</p>
    <p>Furthermore, if you are on your home plane when you cast this
spell, nongood extraplanar creatures within the area are instantly
banished back to their home planes. Creatures so banished cannot return
for at least 24 hours. This effect takes place regardless of whether
the creatures hear the holy word. The banishment effect allows a Will
save (at a &ndash;4 penalty) to negate.</p>
    <p>Creatures whose HD exceed your caster level are unaffected by
holy word.</p>
    <h6><a id="horrid-wilting">Horrid Wilting</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8, Water 8</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Targets</b>: Living
creatures, no two
of which can be more than 60 ft. apart</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude half</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>This spell evaporates moisture from the body of each subject
living creature, dealing 1d6 points of damage per caster level (maximum
20d6). This spell is especially devastating to water elementals and
plant creatures, which instead take 1d8 points of damage per caster
level (maximum 20d8).</p>
    <p><i>Arcane Material Component</i>: A bit of sponge.</p>
    <h6><a id="hypnotic-pattern">Hypnotic Pattern</a></h6>
    <p className="initial"><i>Illusion (Pattern) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V (Brd only), S, M;
see text</span> <span className="stat-block"><b>Casting Time</b>: 1
standard action</span> <span className="stat-block"><b>Range</b>: Medium
(100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Colorful
lights in a
10-ft.-radius spread</span> <span className="stat-block"><b>Duration</b>:
Concentration + 2 rounds</span> <span className="stat-block"><b>Saving
Throw</b>: Will negates</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>A twisting pattern of subtle, shifting colors weaves through
the air, fascinating creatures within it. Roll 2d4 and add your caster
level (maximum 10) to determine the total number of Hit Dice of
creatures affected. Creatures with the fewest HD are affected first;
and, among creatures with equal HD, those who are closest to the
spell&rsquo;s point of origin are affected first. Hit Dice that are not
sufficient to affect a creature are wasted. Affected creatures become <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a> by the pattern of
colors. Sightless creatures are not affected.</p>
    <p>A wizard or sorcerer need not utter a sound to cast this
spell, but a bard must sing, play music, or recite a rhyme as a verbal
component.</p>
    <p><i>Material Component</i>: A glowing stick of incense or a
crystal rod filled with phosphorescent material.</p>
    <h6><a id="hypnotism">Hypnotism</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: Several living
creatures,
no two of which may be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 2d4 rounds (D)</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Your gestures and droning incantation fascinate nearby
creatures, causing them to stop and stare blankly at you. In addition,
you can use their rapt attention to make your suggestions and requests
seem more plausible. Roll 2d4 to see how many total Hit Dice of
creatures you affect. Creatures with fewer HD are affected before
creatures with more HD. Only creatures that can see or hear you are
affected, but they do not need to understand you to be <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a>.</p>
    <p>If you use this spell in combat, each target gains a +2 bonus
on its saving throw. If the spell affects only a single creature not in
combat at the time, the saving throw has a penalty of &ndash;2.</p>
    <p>While the subject is fascinated by this spell, it reacts as
though it were two steps more friendly in attitude. This allows you to
make a single request of the affected creature (provided you can
communicate with it). The request must be brief and reasonable. Even
after the spell ends, the creature retains its new attitude toward you,
but only with respect to that particular request.</p>
    <p>A creature that fails its saving throw does not remember that
you enspelled it.</p>
    <h6><a id="ice-storm">Ice Storm</a></h6>
    <p className="initial"><i>Evocation [Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 4, Sor/Wiz 4, Water 5</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Cylinder
(20-ft. radius, 40
ft. high)</span> <span className="stat-block"><b>Duration</b>: 1 full round</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Great magical hailstones pound down for 1 full round, dealing
3d6 points of bludgeoning damage and 2d6 points of cold damage to every
creature in the area. A &ndash;4 penalty applies to each <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
check made within the ice storm&rsquo;s effect, and all land movement
within
its area is at half speed. At the end of the duration, the hail
disappears, leaving no aftereffects (other than the damage dealt).</p>
    <p><i>Arcane Material Component</i>: A pinch of dust and a few
drops of water.</p>
    <h6><a id="identify">Identify</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Magic 2, Sor/Wiz 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 hour</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One touched object</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>The spell determines all magic properties of a single magic
item, including how to activate those functions (if appropriate), and
how many charges are left (if any).</p>
    <p>Identify does not function when used on an artifact.</p>
    <p><i>Arcane Material Component</i>: A pearl of at least 100 gp
value, crushed and stirred into wine with an owl feather; the infusion
must be drunk prior to spellcasting.</p>
    <h6><a id="illusory-script">Illusory Script</a></h6>
    <p className="initial"><i>Illusion (Phantasm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 minute or longer;
see text</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: One touched object
weighing no more than 10 lb.</span> <span className="stat-block"><b>Duration</b>:
One day/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You write instructions or other information on parchment,
paper, or any suitable writing material. The illusory script appears to
be some form of foreign or magical writing. Only the person (or people)
designated by you at the time of the casting are able to read the
writing; it&rsquo;s unintelligible to any other character, although an
illusionist recognizes it as illusory script.</p>
    <p>Any unauthorized creature attempting to read the script
triggers a potent illusory effect and must make a saving throw. A
successful saving throw means the creature can look away with only a
mild sense of disorientation. Failure means the creature is subject to
a suggestion implanted in the script by you at the time the illusory
script spell was cast. The suggestion lasts only 30 minutes. Typical
suggestions include &ldquo;Close the book and leave,&rdquo;
&ldquo;Forget the existence
of the book,&rdquo; and so forth. If successfully dispelled by dispel
magic,
the illusory script and its secret message disappear. The hidden
message can be read by a combination of the true seeing spell with the
read magic or comprehend languages spell.</p>
    <p>The casting time depends on how long a message you wish to
write, but it is always at least 1 minute.</p>
    <p><i>Material Component</i>: A lead-based ink (cost of not less
than 50 gp).</p>
    <h6><a id="illusory-wall">Illusory Wall</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Image 1 ft. by
10 ft. by
10 ft.</span> <span className="stat-block"><b>Duration</b>: Permanent</span>
    <span className="stat-block"><b>Saving Throw</b>: Will disbelief (if
interacted with)</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>This spell creates the illusion of a wall, floor, ceiling, or
similar surface. It appears absolutely real when viewed, but physical
objects can pass through it without difficulty. When the spell is used
to hide pits, traps, or normal doors, any detection abilities that do
not require sight work normally. Touch or a probing search reveals the
true nature of the surface, though such measures do not cause the
illusion to disappear.</p>
    <h6><a id="imbue-with-spell-ability">Imbue with Spell Ability</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Magic 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched; see text</span> <span className="stat-block"><b>Duration</b>:
Permanent until
discharged (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>You transfer some of your currently prepared spells, and the
ability to cast them, to another creature. Only a creature with an
Intelligence score of at least 5 and a Wisdom score of at least 9 can
receive this bestowal. Only cleric spells from the schools of
abjuration, divination, and conjuration (healing) can be transferred.
The number and level of spells that the subject can be granted depends
on its Hit Dice; even multiple castings of imbue with spell ability
can&rsquo;t exceed this limit.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>HD of Recipient</th>
          <th>Spells Imbued</th>
        </tr>
        <tr className="odd-row">
          <td>2 or lower</td>
          <td>One 1st-level spell</td>
        </tr>
        <tr>
          <td>3&ndash;4</td>
          <td>One or two 1st-level spells</td>
        </tr>
        <tr className="odd-row">
          <td>5 or higher</td>
          <td>One or two 1st-level spells and one 2nd-level spell
&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <p>The transferred spell&rsquo;s variable characteristics (range,
duration, area, and the like) function according to your level, not the
level of the recipient.</p>
    <p>Once you cast imbue with spell ability, you cannot prepare a
new 4th-level spell to replace it until the recipient uses the imbued
spells or is slain, or until you dismiss the imbue with spell ability
spell. In the meantime, you remain responsible to your deity or your
principles for the use to which the spell is put. If the number of
4th-level spells you can cast decreases, and that number drops below
your current number of active imbue with spell ability spells, the more
recently cast imbued spells are dispelled.</p>
    <p>To cast a spell with a verbal component, the subject must be
able to speak. To cast a spell with a somatic component, it must have
humanlike hands. To cast a spell with a material component or focus, it
must have the materials or focus.</p>
    <h6><a id="implosion">Implosion</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Destruction 9</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One corporeal
creature/round</span> <span className="stat-block"><b>Duration</b>:
Concentration (up to 4
rounds)</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude
negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You create a destructive resonance in a corporeal
creature&rsquo;s
body. For each round you concentrate, you cause one creature to
collapse in on itself, killing it. (This effect, being instantaneous,
cannot be dispelled.)</p>
    <p>You can target a particular creature only once with each
casting of the spell.</p>
    <p>Implosion has no effect on creatures in gaseous form or on <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures.</p>
    <h6><a id="imprisonment">Imprisonment</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>When you cast imprisonment and touch a creature, it is
entombed in a state of suspended animation (see the temporal stasis
spell) in a small sphere far beneath the surface of the earth. The
subject remains there unless a freedom spell is cast at the locale
where the imprisonment took place. Magical search by a crystal ball, a
locate object spell, or some other similar divination does not reveal
the fact that a creature is imprisoned, but discern location does. A
wish or miracle spell will not free the recipient, but will reveal
where it is entombed. If you know the target&rsquo;s name and some
facts
about its life, the target takes a &ndash;4 penalty on its save.</p>
    <h6><a id="incendiary-cloud">Incendiary Cloud</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Fire 8, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Cloud
spreads in 20-ft.
radius, 20 ft. high</span> <span className="stat-block"><b>Duration</b>: 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex half; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>An incendiary cloud spell creates a cloud of roiling smoke
shot through with white-hot embers. The smoke obscures all sight as a
fog cloud does. In addition, the white-hot embers within the cloud deal
4d6 points of fire damage to everything within the cloud on your turn
each round. All targets can make Reflex saves each round to take half
damage.</p>
    <p>As with a cloudkill spell, the smoke moves away from you at 10
feet per round. Figure out the smoke&rsquo;s new spread each round
based on
its new point of origin, which is 10 feet farther away from where you
were when you cast the spell. By concentrating, you can make the cloud
(actually its point of origin) move as much as 60 feet each round. Any
portion of the cloud that would extend beyond your maximum range
dissipates harmlessly, reducing the remainder&rsquo;s spread thereafter.</p>
    <p>As with fog cloud, wind disperses the smoke, and the spell
can&rsquo;t be cast underwater.</p>
    <h6><a id="inflict-critical-wounds">Inflict Critical Wounds</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Destruction 4</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#inflict-light-wounds">inflict light wounds</a>, except that
you deal 4d8 points of damage +1 point per caster level (maximum +20).</p>
    <h6><a id="mass-inflict-critical-wounds">Inflict Critical
Wounds, Mass</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-inflict-light-wounds">mass inflict light wounds</a>, except
that it deals 4d8 points of damage +1 point per caster level (maximum
+40).</p>
    <h6><a id="inflict-light-wounds">Inflict Light Wounds</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Destruction 1</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>When laying your hand upon a creature, you channel negative
energy that deals 1d8 points of damage +1 point per caster level
(maximum +5).</p>
    <p>Since undead are powered by negative energy, this spell cures
such a creature of a like amount of damage, rather than harming it.</p>
    <h6><a id="mass-inflict-light-wounds">Inflict Light Wounds, Mass</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Destruction 5</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Negative energy spreads out in all directions from the point
of origin, dealing 1d8 points of damage +1 point per caster level
(maximum +25) to nearby living enemies.</p>
    <p>Like other inflict spells, mass inflict light wounds cures
undead in its area rather than damaging them. A cleric capable of
spontaneously casting inflict spells can also spontaneously cast mass
inflict spells.</p>
    <h6><a id="inflict-minor-wounds">Inflict Minor Wounds</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#inflict-light-wounds">inflict light wounds</a>, except that
you deal 1 point of damage and a Will save negates the damage instead
of halving it.</p>
    <h6><a id="inflict-moderate-wounds">Inflict Moderate Wounds</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#inflict-light-wounds">inflict light wounds</a>, except that
you deal 2d8 points of damage +1 point per caster level (maximum +10).</p>
    <h6><a id="mass-inflict-moderate-wounds">Inflict Moderate
Wounds, Mass</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-inflict-light-wounds">mass inflict light wounds</a>, except
that it deals 2d8 points of damage +1 point per caster level (maximum
+30).</p>
    <h6><a id="inflict-serious-wounds">Inflict Serious Wounds</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#inflict-light-wounds">inflict light wounds</a>, except that
you deal 3d8 points of damage +1 point per caster level (maximum +15).</p>
    <h6><a id="mass-inflict-serious-wounds">Inflict Serious Wounds,
Mass</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-inflict-light-wounds">mass inflict light wounds</a>, except
that it deals 3d8 points of damage +1 point per caster level (maximum
+35).</p>
    <h6><a id="insanity">Insanity</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The affected creature suffers from a continuous confusion
effect, as the spell.</p>
    <p>Remove curse does not remove insanity. Greater restoration,
heal, limited wish, miracle, or wish can restore the creature.</p>
    <h6><a id="insect-plague">Insect Plague</a></h6>
    <p className="initial"><i>Conjuration (Summoning)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 5</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Effect</b>: One swarm of
locusts per
three levels, each of which must be adjacent to at least one other swarm</span>
    <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You summon a number of swarms of locusts (one per three
levels, to a maximum of six swarms at 18th level). The swarms must be
summoned so that each one is adjacent to at least one other swarm (that
is, the swarms must fill one contiguous area). You may summon the
locust swarms so that they share the area of other creatures. Each
swarm attacks any creatures occupying its area. The swarms are
stationary after being summoned, and won&rsquo;t pursue creatures that
flee.</p>
    <h6><a id="instant-summons">Instant Summons</a></h6>
    <p className="initial"><i>Conjuration (Summoning)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: See text</span> <span className="stat-block"><b>Target</b>: One object weighing 10
lb. or less whose longest dimension is 6 ft. or less</span> <span className="stat-block"><b>Duration</b>: Permanent until
discharged</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You call some nonliving item from virtually any location
directly to your hand.</p>
    <p>First, you must place your arcane mark on the item. Then you
cast this spell, which magically and invisibly inscribes the name of
the item on a sapphire worth at least 1,000 gp. Thereafter, you can
summon the item by speaking a special word (set by you when the spell
is cast) and crushing the gem. The item appears instantly in your hand.
Only you can use the gem in this way.</p>
    <p>If the item is in the possession of another creature, the
spell does not work, but you know who the possessor is and roughly
where that creature is located when the summons occurs.</p>
    <p>The inscription on the gem is <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>. It is also
unreadable, except by means of a read magic spell, to anyone but you.</p>
    <p>The item can be summoned from another plane, but only if no
other creature has claimed ownership of it.</p>
    <p><i>Material Component</i>: A sapphire worth at least 1,000 gp.</p>
    <h6><a id="interposing-hand">Interposing Hand</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: 10-ft. hand</span>
    <span className="stat-block"><b>Duration</b>: 1 round/level (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Interposing hand creates a Large magic hand that appears
between you and one opponent. This floating, disembodied hand then
moves to remain between the two of you, regardless of where you move or
how the opponent tries to get around it, providing cover (+4 AC) for
you against that opponent. Nothing can fool the hand&mdash;it sticks
with the
selected opponent in spite of darkness, invisibility, polymorphing, or
any other attempt at hiding or disguise. The hand does not pursue an
opponent, however.</p>
    <p>An interposing hand is 10 feet long and about that wide with
its fingers outstretched. It has as many hit points as you do when
you&rsquo;re undamaged, and its AC is 20 (&ndash;1 size, +11 natural).
It takes
damage as a normal creature, but most magical effects that don&rsquo;t
cause
damage do not affect it.</p>
    <p>The hand never provokes attacks of opportunity from opponents.
It cannot push through a wall of force or enter an antimagic field, but
it suffers the full effect of a prismatic wall or prismatic sphere. The
hand makes saving throws as its caster.</p>
    <p>Disintegrate or a successful dispel magic destroys it.</p>
    <p>Any creature weighing 2,000 pounds or less that tries to push
past the hand is slowed to half its normal speed. The hand cannot
reduce the speed of a creature weighing more than 2,000 pounds, but it
still affects the creature&rsquo;s attacks.</p>
    <p>Directing the spell to a new target is a move action.</p>
    <p><i>Focus</i>: A soft glove.</p>
    <h6><a id="invisibility">Invisibility</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2, Trickery
2</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Personal or touch</span> <span className="stat-block"><b>Target</b>: You or a creature or
object weighing no more than 100 lb./level</span> <span className="stat-block"><b>Duration</b>: 1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless) or Will negates (harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)
or Yes (harmless, object)</span>
    <p>The creature or object touched becomes <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, vanishing from sight,
even from darkvision. If the recipient is a creature carrying gear,
that vanishes, too. If you cast the spell on someone else, neither you
nor your allies can see the subject, unless you can normally see
invisible things or you employ magic to do so.</p>
    <p>Items dropped or put down by an invisible creature become
visible; items picked up disappear if tucked into the clothing or
pouches worn by the creature. Light, however, never becomes invisible,
although a source of light can become so (thus, the effect is that of a
light with no visible source). Any part of an item that the subject
carries but that extends more than 10 feet from it becomes visible.</p>
    <p>Of course, the subject is not magically silenced, and certain
other conditions can render the recipient detectable (such as stepping
in a puddle). The spell ends if the subject attacks any creature. For
purposes of this spell, an attack includes any spell targeting a foe or
whose area or effect includes a foe. (Exactly who is a foe depends on
the invisible character&rsquo;s perceptions.) Actions directed at
unattended
objects do not break the spell. Causing harm indirectly is not an
attack. Thus, an invisible being can open doors, talk, eat, climb
stairs, summon monsters and have them attack, cut the ropes holding a
rope bridge while enemies are on the bridge, remotely trigger traps,
open a portcullis to release attack dogs, and so forth. If the subject
attacks directly, however, it immediately becomes visible along with
all its gear. Spells such as bless that specifically affect allies but
not foes are not attacks for this purpose, even when they include foes
in their area.</p>
    <p>Invisibility can be made permanent (on objects only) with a
permanency spell.</p>
    <p><i>Arcane Material Component</i>: An eyelash encased in a bit
of gum arabic.</p>
    <h6><a id="greater-invisibility">Invisibility, Greater</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Target</b>: You or creature touched</span> <span className="stat-block"><b>Duration</b>: 1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#invisibility">invisibility</a>, except that it
doesn&rsquo;t
end if the subject attacks.</p>
    <h6><a id="mass-invisibility">Invisibility, Mass</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Targets</b>: Any number
of creatures,
no two of which can be more than 180 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#invisibility">invisibility</a>, except that the effect
is mobile with the group and is broken when anyone in the group
attacks. Individuals in the group cannot see each other. The spell is
broken for any individual who moves more than 180 feet from the nearest
member of the group. (If only two individuals are affected, the one
moving away from the other one loses its invisibility. If both are
moving away from each other, they both become visible when the distance
between them exceeds 180 feet.)</p>
    <p><i>Material Component</i>: An eyelash encased in a bit of gum
arabic.</p>
    <h6><a id="invisibility-purge">Invisibility Purge</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>You surround yourself with a sphere of power with a radius of
5 feet per caster level that negates all forms of invisibility.</p>
    <p>Anything <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> becomes visible while
in the area.</p>
    <h6><a id="invisibility-sphere">Invisibility Sphere</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius emanation
around the creature or object touched</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#invisibility">invisibility</a>, except that this spell
confers invisibility upon all creatures within 10 feet of the
recipient. The center of the effect is mobile with the recipient.</p>
    <p>Those affected by this spell can see each other and themselves
as if unaffected by the spell. Any affected creature moving out of the
area becomes visible, but creatures moving into the area after the
spell is cast do not become <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>. Affected creatures
(other than the recipient) who attack negate the invisibility only for
themselves. If the spell recipient attacks, the invisibility sphere
ends.</p>
    <h6><a id="iron-body">Iron Body</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Earth 8, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>This spell transforms your body into living iron, which grants
you several powerful resistances and abilities.</p>
    <p>You gain <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> 15/adamantine. You are immune to
blindness, critical hits, ability score damage, deafness, disease,
drowning, electricity, poison, stunning, and all spells or attacks that
affect your physiology or respiration, because you have no physiology
or respiration while this spell is in effect. You take only half damage
from acid and fire of all kinds. However, you also become vulnerable to
all special attacks that affect iron golems.</p>
    <p>You gain a +6 enhancement bonus to your Strength score, but
you take a &ndash;6 penalty to Dexterity as well (to a minimum
Dexterity
score of 1), and your speed is reduced to half normal. You have an
arcane spell failure chance of 50% and a &ndash;8 armor check penalty,
just
as if you were clad in full plate armor. You cannot drink (and thus
can&rsquo;t use potions) or play wind instruments.</p>
    <p>Your unarmed attacks deal damage equal to a club sized for you
(1d4 for Small characters or 1d6 for Medium characters), and you are
considered armed when making unarmed attacks.</p>
    <p>Your weight increases by a factor of ten, causing you to sink
in water like a stone. However, you could survive the crushing pressure
and lack of air at the bottom of the ocean&mdash;at least until the
spell
duration expires.</p>
    <p><i>Arcane Material Component</i>: A small piece of iron that
was once part of either an iron golem, a hero&rsquo;s armor, or a war
machine.</p>
    <h6><a id="ironwood">Ironwood</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 minute/lb. created</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
An ironwood object
weighing up to 5 lb./level</span> <span className="stat-block"><b>Duration</b>:
One day/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Ironwood is a magical substance created by druids from normal
wood. While remaining natural wood in almost every way, ironwood is as
strong, heavy, and resistant to fire as steel. Spells that affect metal
or iron do not function on ironwood. Spells that affect wood do affect
ironwood, although ironwood does not burn. Using this spell with wood
shape or a wood-related <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a> check, you can fashion
wooden items that function as steel items. Thus, wooden plate armor and
wooden swords can be created that are as durable as their normal steel
counterparts. These items are freely usable by druids.</p>
    <p>Further, if you make only half as much ironwood as the spell
would normally allow, any weapon, shield, or suit of armor so created
is treated as a magic item with a +1 enhancement bonus.</p>
    <p><i>Material Component</i>: Wood shaped into the form of the
intended ironwood object.</p>
    <h6><a id="irresistible-dance">Irresistible Dance</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
1d4+1 rounds</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject feels an undeniable urge to dance and begins doing
so, complete with foot shuffling and tapping. The spell effect makes it
impossible for the subject to do anything other than caper and prance
in place. The effect imposes a &ndash;4 penalty to Armor Class and a
&ndash;10
penalty on Reflex saves, and it negates any AC bonus granted by a
shield the target holds. The dancing subject provokes attacks of
opportunity each round on its turn.</p>
    <h6><a id="jump">Jump</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1, Sor/Wiz 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject gets a +10 enhancement bonus on <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
checks. The enhancement bonus increases to +20 at caster level 5th, and
to +30 (the maximum) at caster level 9th.</p>
    <p><i>Material Component</i>: A grasshopper&rsquo;s hind leg,
which you
break when the spell is cast.</p>
    <h6><a id="keen-edge">Keen Edge</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One weapon or
fifty
projectiles, all of which must be in contact with each other at the
time of casting</span> <span className="stat-block"><b>Duration</b>: 10
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>This spell makes a weapon magically keen, improving its
ability to deal telling blows. This transmutation doubles the threat
range of the weapon. A threat range of 20 becomes 19&ndash;20, a threat
range
of 19&ndash;20 becomes 17&ndash;20, and a threat range of 18&ndash;20
becomes 15&ndash;20. The
spell can be cast only on piercing or slashing weapons. If cast on
arrows or crossbow bolts, the keen edge on a particular projectile ends
after one use, whether or not the missile strikes its intended target.
(Treat shuriken as arrows, rather than as thrown weapons, for the
purpose of this spell.)</p>
    <p>Multiple effects that increase a weapon&rsquo;s threat range
(such
as the keen edge spell and the <a href="featsAll.html#improved-critical" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Critical</a> feat)
don&rsquo;t
stack. You can&rsquo;t cast this spell on a natural weapon, such as a
claw.</p>
    <h6><a id="knock">Knock</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One door,
box, or chest
with an area of up to 10 sq. ft./level</span> <span className="stat-block"><b>Duration</b>:
Instantaneous; see text</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>The knock spell opens stuck, barred, locked, held, or arcane
locked doors. It opens secret doors, as well as locked or trick-opening
boxes or chests. It also loosens welds, shackles, or chains (provided
they serve to hold closures shut). If used to open a arcane locked
door, the spell does not remove the arcane lock but simply suspends its
functioning for 10 minutes. In all other cases, the door does not
relock itself or become stuck again on its own. Knock does not raise
barred gates or similar impediments (such as a portcullis), nor does it
affect ropes, vines, and the like. The effect is limited by the area.
Each spell can undo as many as two means of preventing egress. </p>
    <h6><a id="know-direction">Know Direction</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Drd 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span>
    <p>You instantly know the direction of north from your current
position. The spell is effective in any environment in which
&ldquo;north&rdquo;
exists, but it may not work in extraplanar settings. Your knowledge of
north is correct at the moment of casting, but you can get lost again
within moments if you don&rsquo;t find some external reference point to
help
you keep track of direction.</p>
    <h6><a id="legend-lore">Legend Lore</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Knowledge 7,
Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span>
    <span className="stat-block"><b>Casting Time</b>: See text</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
See text</span>
    <p>Legend lore brings to your mind legends about an important
person, place, or thing. If the person or thing is at hand, or if you
are in the place in question, the casting time is only 1d4x10 minutes.
If you have only detailed information on the person, place, or thing,
the casting time is 1d10 days, and the resulting lore is less complete
and specific (though it often provides enough information to help you
find the person, place, or thing, thus allowing a better legend lore
result next time). If you know only rumors, the casting time is 2d6
weeks, and the resulting lore is vague and incomplete (though it often
directs you to more detailed information, thus allowing a better legend
lore result next time).</p>
    <p><i>During the casting, you cannot engage in other than routine
activities</i>: eating, sleeping, and so forth. When completed, the
divination brings legends (if any) about the person, place, or things
to your mind. These may be legends that are still current, legends that
have been forgotten, or even information that has never been generally
known. If the person, place, or thing is not of legendary importance,
you gain no information. As a rule of thumb, characters who are 11th
level and higher are &ldquo;legendary,&rdquo; as are the sorts of
creatures they
contend with, the major magic items they wield, and the places where
they perform their key deeds.</p>
    <p><i>Material Component</i>: Incense worth at least 250 gp.</p>
    <p><i>Focus</i>: Four strips of ivory (worth 50 gp each) formed
into a rectangle.</p>
    <h6><a id="lesser-spell-name">Lesser (Spell Name)</a></h6>
    <p className="initial"><i>Any spell whose name begins with lesser is
alphabetized in this chapter according to the second word of the spell
name. Thus, the description of a lesser spell appears near the
description of the spell on which it is based. Spell chains that have
lesser spells in them include those based on the spells confusion,
geas, globe of invulnerability, planar ally, planar binding, and
restoration.</i></p>
    <h6><a id="levitate">Levitate</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal or close (25 ft.
+ 5 ft./2 levels)</span> <span className="stat-block"><b>Target</b>: You
or one willing
creature or one object (total weight up to 100 lb./level)</span> <span className="stat-block"><b>Duration</b>: 1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Levitate allows you to move yourself, another creature, or an
object up and down as you wish. A creature must be willing to be
levitated, and an object must be unattended or possessed by a willing
creature. You can mentally direct the recipient to move up or down as
much as 20 feet each round; doing so is a move action. You cannot move
the recipient horizontally, but the recipient could clamber along the
face of a cliff, for example, or push against a ceiling to move
laterally (generally at half its base land speed).</p>
    <p>A levitating creature that attacks with a melee or ranged
weapon finds itself increasingly unstable; the first attack has a
&ndash;1
penalty on attack rolls, the second &ndash;2, and so on, to a maximum
penalty
of &ndash;5. A full round spent stabilizing allows the creature to
begin
again at &ndash;1.</p>
    <p><i>Focus</i>: Either a small leather loop or a piece of golden
wire bent into a cup shape with a long shank on one end.</p>
    <h6><a id="light">Light</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Clr 0, Drd 0,
Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Object touched</span> <span className="stat-block"><b>Duration</b>: 10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell causes an object to glow like a torch, shedding
bright light in a 20-foot radius (and dim light for an additional 20
feet) from the point you touch. The effect is immobile, but it can be
cast on a movable object. Light taken into an area of magical darkness
does not function.</p>
    <p>A light spell (one with the light descriptor) counters and
dispels a darkness spell (one with the darkness descriptor) of an equal
or lower level.</p>
    <p><i>Arcane Material Component</i>: A firefly or a piece of
phosphorescent moss.</p>
    <h6><a id="lightning-bolt">Lightning Bolt</a></h6>
    <p className="initial"><i>Evocation [Electricity]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 120 ft.</span> <span className="stat-block"><b>Area</b>: 120-ft. line</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You release a powerful stroke of electrical energy that deals
1d6 points of electricity damage per caster level (maximum 10d6) to
each creature within its area. The bolt begins at your fingertips.</p>
    <p>The lightning bolt sets fire to combustibles and damages
objects in its path. It can melt metals with a low melting point, such
as lead, gold, copper, silver, or bronze. If the damage caused to an
interposing barrier shatters or breaks through it, the bolt may
continue beyond the barrier if the spell&rsquo;s range permits;
otherwise, it
stops at the barrier just as any other spell effect does.</p>
    <p><i>Material Component</i>: A bit of fur and an amber, crystal,
or glass rod.</p>
    <h6><a id="limited-wish">Limited Wish</a></h6>
    <p className="initial"><i>Universal</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, XP</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: See text</span> <span style={{
      "fontWeight": "bold"
    }}>Target, Effect, or Area:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None; see
text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>A limited wish lets you create nearly any type of effect. For
example, a limited wish can do any of the following things.</p>
    <ul>
      <li>
        <p>Duplicate any sorcerer/wizard spell of 6th level or lower,
provided the spell is not of a school prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any other spell of 5th level or lower, provided
the spell is not of a school prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any sorcerer/wizard spell of 5th level or lower,
even if it&rsquo;s of a prohibited school.</p>
      </li>
      <li>
        <p>Duplicate any other spell of 4th level or lower, even if
it&rsquo;s of a prohibited school.</p>
      </li>
      <li>
        <p>Undo the harmful effects of many spells, such as
geas/quest or insanity.</p>
      </li>
      <li>
        <p>Produce any other effect whose power level is in line with
the above effects, such as a single creature automatically hitting on
its next attack or taking a &ndash;7 penalty on its next saving throw.</p>
      </li>
    </ul>
    <p>A duplicated spell allows saving throws and spell resistance
as normal (but the save DC is for a 7th-level spell). When a limited
wish duplicates a spell that has an XP cost, you must pay that cost or
300 XP, whichever is more. When a limited wish spell duplicates a spell
with a material component that costs more than 1,000 gp, you must
provide that component.</p>
    <p><i>XP Cost</i>: 300 XP or more (see above).</p>
    <h6><a id="liveoak">Liveoak</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 6</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Tree touched</span> <span className="stat-block"><b>Duration</b>: One
day/level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell turns an oak tree into a protector or guardian. The
spell can be cast on only a single tree at a time; while liveoak is in
effect, you can&rsquo;t cast it again on another tree. The tree on
which the
spell is cast must be within 10 feet of your dwelling place, within a
place sacred to you, or within 300 feet of something that you wish to
guard or protect.</p>
    <p>Liveoak must be cast on a healthy, Huge oak. A triggering
phrase of up to one word per caster level is placed on the targeted
oak. The liveoak spell triggers the tree into animating as a treant. </p>
    <p>If liveoak is dispelled, the tree takes root immediately,
wherever it happens to be. If released by you, the tree tries to return
to its original location before taking root.</p>
    <h6><a id="locate-creature">Locate Creature</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#locate-object">locate object</a>, except this spell
locates a known or familiar creature.</p>
    <p>You slowly turn and sense when you are facing in the direction
of the creature to be located, provided it is within range. You also
know in which direction the creature is moving, if any.</p>
    <p>The spell can locate a creature of a specific kind or a
specific creature known to you. It cannot find a creature of a certain
type. To find a kind of creature, you must have seen such a creature up
close (within 30 feet) at least once.</p>
    <p>Running water blocks the spell. It cannot detect objects. It
can be fooled by mislead, nondetection, and polymorph spells.</p>
    <p><i>Material Component</i>: A bit of fur from a bloodhound.</p>
    <h6><a id="locate-object">Locate Object</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 3, Sor/Wiz 2,
Travel 2</span> <span className="stat-block"><b>Components</b>: V, S, F/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Circle,
centered on you,
with a radius of 400 ft. + 40 ft./level</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You sense the direction of a well-known or clearly visualized
object. You can search for general items, in which case you locate the
nearest one of its kind if more than one is within range. Attempting to
find a certain item requires a specific and accurate mental image; if
the image is not close enough to the actual object, the spell fails.
You cannot specify a unique item unless you have observed that
particular item firsthand (not through divination).</p>
    <p>The spell is blocked by even a thin sheet of lead. Creatures
cannot be found by this spell. Polymorph any object fools it.</p>
    <p><i>Arcane Focus</i>: A forked twig.</p>
    <h6><a id="longstrider">Longstrider</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1, Travel 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level (D)</span>
    <p>This spell increases your base land speed by 10 feet. (This
adjustment counts as an enhancement bonus.) It has no effect on other
modes of movement, such as burrow, climb, fly, or swim.</p>
    <p><i>Material Component</i>: A pinch of dirt.</p>
    <h6><a id="lullaby">Lullaby</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Living
creatures within a
10-ft.-radius burst</span> <span className="stat-block"><b>Duration</b>:
Concentration + 1
round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Any creature within the area that fails a Will save becomes
drowsy and inattentive, taking a &ndash;5 penalty on <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
checks and a &ndash;2 penalty on Will saves against sleep effects while
the
lullaby is in effect. Lullaby lasts for as long as the caster
concentrates, plus up to 1 round per caster level thereafter.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      